import React from 'react'
import Seo from '../../components/molecules/seo'
import Layout from '../../components/molecules/layout'
import SignUpModal from '../../components/sign-up-modal'
import { ClerkLoaded } from '@clerk/clerk-react'
import Diagram from '../../assets/ptn-diagram.svg'
import { Link } from 'gatsby'

const HowItWorks = (props) => {
  const title = 'how it works'
  const belowDiagramText = 'below'
  const rightDiagramText = 'to the right'
  const smallCutoff = 768
  const initialPosition =
    typeof window !== 'undefined' && window.innerWidth < smallCutoff
      ? belowDiagramText
      : rightDiagramText
  const [diagramPosition, setDiagramPosition] = React.useState(initialPosition)

  const handleResize = () => {
    if (window.innerWidth < smallCutoff) {
      setDiagramPosition(belowDiagramText)
    } else {
      setDiagramPosition(rightDiagramText)
    }
  }

  if (typeof window !== 'undefined') {
    window.addEventListener('resize', handleResize)
  }

  return (
    <Layout location={props.location}>
      <Seo
        title={title}
        description="send us your messages, we put them into your roam graph
"
      />
      <section className="hero is-primary">
        <div className="hero-body">
          <h1 className="page-title">{title}</h1>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-full">
              <div className="how-it-works-section">
                <h2>
                  send us messages, we put them into your tools for thought
                </h2>
                <p>
                  we work where you already are. quickly capture thoughts on the
                  go or from your desk.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <main className="how-it-works">
                <div className="how-it-works-section">
                  <h3>mobile quick capture</h3>
                  <p>
                    after signing up, you'll receive a{' '}
                    <strong>phone number</strong> to text, plus our{' '}
                    <strong>fb messenger</strong> and <strong>telegram</strong>{' '}
                    bots to message
                  </p>
                  <p>
                    you'll also receive an <strong>email</strong> to send
                    messages straight from your inbox to your daily notes
                  </p>
                </div>
                <div>
                  <h3>client sync</h3>
                  <p>
                    your messages are stored in a{' '}
                    <Link to="/changelog/steps-towards-data-interoperability">
                      secure feed
                    </Link>{' '}
                    and fetched when you open a{' '}
                    <Link to="/pages/clients">supported client</Link> as
                    illustrated {diagramPosition}
                  </p>
                </div>
              </main>
            </div>
            <div className="column is-4" id="diagram-wrapper">
              <Diagram></Diagram>
            </div>
          </div>
          <div className="columns">
            <div className="column is-full ">
              <div className="how-it-works-section">
                <ClerkLoaded>
                  <SignUpModal />
                </ClerkLoaded>
              </div>
              <h3>desktop power tools</h3>
              <p>
                use our{' '}
                <a href="https://ptn.gumroad.com/l/ptn-alfred">alfred plugin</a>{' '}
                to send messages from <strong>macOS</strong>
              </p>
              <p>
                sync <strong>browser</strong> tabs and bookmarks with our{' '}
                <Link to="/changelog/chrometonote-v1">chrome extension</Link>
              </p>
              <p>
                our {` `}
                <a href="/pages/integrations">zapier integration</a>
                {` `}lets you pull in RSS feeds, calendar events, and data from
                other software.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default HowItWorks
